<template>
  <div class="flex flex-col flex-1">
    <Topbar :pages="pages"></Topbar>
    <div class="flex flex-col p-6  flex-1">
      <div
          class="bg-white shadow overflow-hidden sm:rounded-lg mb-2"
          v-for="section in survey_sections"
          v-bind:key="section.id"
      >
        <div class="px-4 py-5 sm:px-6">
          <h3 class="text-l font-bold mb-2 text-gray-600">
            {{ section.section_content.section_name }}
          </h3>
          <div class="px-4 py-5 sm:p-0 border rounded border-red-400">
            <Question v-for="question in section.section_content.questions"
                      :question="question"
                      :result="surveyResult.survey_result"
                      :child="!!question.children.questions.length"
                      v-bind:key="question.name">
            </Question>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import orderBy from 'lodash-es/orderBy';
import map from 'lodash-es/map';
import API, {graphqlOperation} from '@aws-amplify/api';
import {getSurvey, getSurveyResult, listSurveySections} from '@/graphql/queries';
import Question from './components/Question';

export default {
  components: {
    Question,
  },
  data() {
    return {
      survey: null,
      surveyResult: null,
      isLoading: false,
      survey_sections: null,
    };
  },
  computed: {
    id() {
      return this.$route.params.id;
    },
    result() {
      return this.$route.params.result;
    },
    pages() {
      return [
        {
          name: 'Surveys',
          to: {name: 'surveys'},
          current: false,
        },
        {
          name: this.survey ? this.survey.survey_name : 'Surveys View',
          to: {name: 'surveys.results', params: {id: this.id}},
          current: false,
        },
        {
          name: 'Result View',
          to: {name: 'surveys.view', params: {id: this.id, result: this.result}},
          current: true,
        },
      ];
    },
  },
  methods: {
    async loadSurvey() {
      await API.graphql(graphqlOperation(getSurvey, {id: this.id})).then(
          async ({data: {getSurvey}}) => {
            this.survey = getSurvey;
            this.survey.survey_content_parsed = JSON.parse(this.survey.survey_content);
            await API.graphql(graphqlOperation(listSurveySections, {survey_id: this.id})).then(
                ({
                   data: {
                     listSurveySections: {items},
                   },
                 }) => {
                  this.survey_sections = map(orderBy(items, 'ordering'), section => {
                    // console.log(section);
                    section.section_content = JSON.parse(section.section_content);
                    return section;
                  });
                  this.isLoading = false;
                },
            );
          },
      );
    },
    async loadResult() {
      await API.graphql(graphqlOperation(getSurveyResult, {id: this.result})).then(
          async ({data: {getSurveyResult}}) => {
            console.log(getSurveyResult, 'getSurveyResult');
            this.surveyResult = getSurveyResult;
            this.surveyResult.survey_result = JSON.parse(getSurveyResult.survey_result);
          },
      );
    },
  },
  async mounted() {
    await this.loadResult();
    await this.loadSurvey();
  },
};
</script>
